import React from "react"


import SEO from "../components/seo"
import Layout from "../components/layout"
import { Container, Row, Col } from "react-bootstrap"
const NotFoundPage = () => (
	<Layout>
		<SEO title="404: Not found" />
		<Container>
			<h1 className="text-center mt-5">NOT FOUND</h1>
		<Row className="mt-5 justify-content-center">
			<Col xs={10} md={8}><p>You just hit a route that doesn&#39;t exist... the sadness.</p></Col>
		</Row>
		</Container>
	</Layout>
)

export default NotFoundPage
